import React from 'react';
// Components

const DashboardContainer = React.lazy(() =>
    import('../../pages/dashboard/DashboardContainer')
);
const ContainerDOT = React.lazy(() =>
    import('../../pages/dot/ContainerDOT')
);
const EquipmentContainer = React.lazy(() =>
    import('../../pages/equipment/EquipmentContainer')
);
const JobsContainer = React.lazy(() =>
    import('../../pages/jobs/JobsContainer')
);
const TemplatesContainer = React.lazy(() =>
    import('../../pages/templates/TemplatesContainer')
);
const SkyviewContainer = React.lazy(() =>
    import('../../pages/skyview/SkyviewContainer')
);
const ReportContainer = React.lazy(() =>
    import('../../pages/reports/ReportContainer')
);
const OptionsContainer = React.lazy(() =>
    import("../../pages/options/OptionsContainer")
);
const AdminContainer = React.lazy(() =>
    import("../../pages/admin/AdminContainer")
);
const AppContainer = React.lazy(() =>
    import("../../pages/app/AppContainer")
);
const APIContainer = React.lazy(() =>
    import("../../pages/api_info/APIContainer")
);
const ReportingContainer = React.lazy(() =>
    import("../../pages/reporting/ReportingContainer")
);
const InternalDashboardContainer = React.lazy(() =>
    import("../../pages/internal_dashboard/InternalDashboardContainer")
);

const TruckDesignerContainer = React.lazy(() =>
    import("../../pages/truck_designer/TruckDesignerContainer")
);
const Page404 = React.lazy(() => import("../components/Page404"));

const routes = {
    dashboard: {
        path: "/dashboard",
        component: DashboardContainer,
        errorMessage: "BUG PAGE :: Dashboard Container Crashed",
        tool: "Dashboard"
    },
    skyview: {
        path: "/skyview",
        component: SkyviewContainer,
        errorMessage: "BUG PAGE :: Skyview Container Crashed",
        tool: "Skyview"
    },
    options: {
        path: "/options",
        component: OptionsContainer,
        errorMessage: "BUG PAGE :: Options Container Crashed",
        tool: "Options"
    },
    equipment: {
        path: "/equipment",
        component: EquipmentContainer,
        errorMessage: "BUG PAGE :: Equipment Container Crashed",
        tool: "Equipment"
    },
    jobs: {
        path: "/jobs",
        component: JobsContainer,
        errorMessage: "BUG PAGE :: Jobs Management Container Crashed",
        tool: "Jobs Management"
    },
    reports: {
        path: "/reports",
        component: ReportContainer,
        errorMessage: "BUG PAGE :: Report Container Crashed",
        tool: "Report"
    },
    admin: {
        path: "/admin",
        component: AdminContainer,
        errorMessage: "BUG PAGE :: Admin Container Crashed",
        tool: "Admin"
    },
    templates: {
        path: "/templates",
        component: TemplatesContainer,
        errorMessage: "BUG PAGE :: Templates Container Crashed",
        tool: "Templates"
    },
    analytics: {
        path: "/analytics",
        component: InternalDashboardContainer,
        errorMessage: "BUG PAGE :: Internal Dashboard Container Crashed",
        tool: "User Dashboard"
    },
    scheduling: {
        path: "/scheduling",
        component: ReportingContainer,
        errorMessage: "BUG PAGE :: Scheduling page crashed",
        tool: "Scheduling"
    },
    app: {
        path: "/app",
        component: AppContainer,
        errorMessage: "BUG PAGE :: App page crashed",
        tool: "App"
    },
    apis: {
        path: "/apis",
        component: APIContainer,
        errorMessage: "BUG PAGE :: API Page",
        tool: "APIs"
    },
    job_share: {
        path: "/job_share",
        component: ContainerDOT,
        errorMessage: "BUG PAGE :: Dot JOB SHARE Container Crashed",
        tool: "Shared Job"
    },
    truck_designer: {
        path: "/truck_designer",
        component: TruckDesignerContainer,
        errorMessage: "BUG PAGE :: Truck Desinger Container Crashed",
        tool: "Truck Designer"
    },
    // error: {
    //     path: "/*",
    //     errorMessage: "BUG PAGE :: 404",
    //     tool: "404",
    //     component: Page404
    // }
};

export default routes;
