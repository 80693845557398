import { List, Map } from "immutable";
import { API } from "../../../Root";
import { catchBlockErrors } from "../../../shared/utils/sentry";

const setPending = (value) => ({
    type: 'setPending',
    payload: value
});

const setError = (error) => ({
    type: 'setError',
    payload: error || null
});

const setItems = (items, dirty = false) => ({
    type: 'setItems',
    payload: { items, dirty }
});

export const updateItem = (index, data, merge = true, dirty = true) => ({
    type: 'updateItem',
    payload: {
        index,
        data,
        merge,
        dirty
    }
})

export const removeItem = (index, dirty = true) => ({
    type: 'removeItem',
    payload: {
        index,
        dirty
    }
});

export const restoreItems = () => ({
    type: 'restoreItems',
    payload: null
});

export const addItem = (itemOption) => async function (dispatch, getState) {
    const state = getState().truckDesigner.hardware;

    dispatch(setPending(true));
    try {
        const { itemID } = itemOption;
        const response = await API.call({
            endpoint: API.endpoints['TD/readTruckItemConfig'],
            method: 'get',
            options: {
                params: { itemID }
            }
        });
        
        if (response.error) throw response.error;
        
        const item = response;
        const updatedItems = state.items.push(item);
        dispatch(setItems(updatedItems, true));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/hardwareStore/addItem' });
    }
    dispatch(setPending(false));

}

export const saveItems = () => async function (dispatch, getState) {
    const hardwareState = getState().truckDesigner.hardware;
    const truckState = getState().truckDesigner.truck;

    if (!hardwareState.dirty) return;

    try {
        dispatch(setPending(true));
        const updatedItems = await API.call({
            endpoint: API.endpoints['TD/updateTruckItems'],
            method: 'POST',
            options: {
                params: { uid: truckState.truckId },
                data: hardwareState.items.toJS()
            }
        });

        if (updatedItems.error) throw updatedItems.error;

        dispatch(setItems(updatedItems, false));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/hardwareStore/saveItems' });
    }
    
    dispatch(setPending(false));
}

export const fetchItems = () => async function (dispatch, getState) {
    const truckState = getState().truckDesigner.truck;
    try {
        dispatch(setPending(true));
        const freshItems = await API.call({
            endpoint: API.endpoints['TD/readTruckItems'],
            method: 'get',
            options: {
                params: { uid: truckState.truckId }
            }
        });
        if (freshItems.error) throw freshItems.error;
        dispatch(setItems(freshItems.map(sub => Map(sub)), false));
        dispatch(setPending(false));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/hardwareStore/fetchItems' });
    }
}

const INITIAL_STATE = {
    dirty: false,
    pending: false,
    error: null,
    items: List([]),
    itemsBackup: null,
};

const HardwareReducer = function (state = INITIAL_STATE, action) {
    const reduce = {

        restoreItems() {
            return {
                ...state,
                dirty: false,
                items: List(state.itemsBackup),
                itemsBackup: null
            }
        },

        removeItem({ index, dirty }) {
            const newItems = state.items.splice(index, 1);
            return {
                ...state,
                dirty,
                items: newItems
            }
        },

        updateItem({ index, data, merge, dirty }) {
            const currentItem = state.items.get(index);
            const updatedItem = merge ? currentItem.merge(data) : Map(data); 
            const updatedItems = state.items.splice(index, 1, updatedItem);

            const updates = {
                ...state,
                dirty,
                items: updatedItems
            };

            const isStateClean = !state.dirty;
            const isUpdateClean = !dirty;
            const isFirstChange = isStateClean && !isUpdateClean;
        
            if (isFirstChange) updates.itemsBackup = state.items.toList();
            if (isUpdateClean) updates.itemsBackup = null;

            return updates;
        },

        setItems({ items, dirty }) {
            return {
                ...state,
                dirty,
                items: List(items)
            }
        },

        setError(error) {
            return { ...state, error }
        },

        setPending(status) {
            return { ...state, pending: status }
        }

    }[action.type];

    return reduce == null ? { ...state } : reduce(action.payload);
};

export { HardwareReducer };
export default HardwareReducer;