import { List, Map } from 'immutable';
import { API } from '../../../Root';
import { catchBlockErrors } from '../../../shared/utils/sentry';

const setPending = (value) => ({
    type: 'setPending',
    payload: value
});

const setError = (error) => ({
    type: 'setError',
    payload: error || null
});

const setTrucks = (data) => ({
    type: 'setTrucks',
    payload: data
});

export const setTruckId = (id) => ({
    type: 'setTruckId',
    payload: id
});

export const updateTruck = (data, merge = true, dirty = true) => ({
    type: 'updateTruck',
    payload: {
        data,
        merge,
        dirty
    }
});

export const updateURL = (navigate) => async function (dispatch, getState) {
    const state = getState().truckDesigner.truck;
    if (state.truckId) navigate(`?id=${state.truckId}`);
    else navigate('?');
}

export const saveTruck = () => async function (dispatch, getState) {
    const state = getState().truckDesigner.truck;

    if (!state.dirty) return;

    try {
        dispatch(setPending(true));
        const truck = state.trucks.find(sub => sub.get('uid') === state.truckId);
        const updatedTruck = await API.call({
            endpoint: API.endpoints['TD/updateTruck'],
            method: 'POST',
            options: {
                data: truck.toJS()
            }
        });

        if (updatedTruck.error) throw updatedTruck.error;

        dispatch(updateTruck(updatedTruck, false));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/truckStore/saveTruck' });
    }
    
    dispatch(setPending(false));
}

export const addTruck = (data) => async function (dispatch, getState) {
    const state = getState().truckDesigner.truck;

    try {
        dispatch(setPending(true));
        const newTruck = await API.call({
            endpoint: API.endpoints['TD/createTruck'],
            method: 'POST',
            options: { data }
        });

        if (newTruck.error) throw newTruck.error;

        dispatch(setTrucks(state.trucks.push(newTruck)));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/truckStore/addTruck' });
    }
    
    dispatch(setPending(false));
}

export const copyTruck = (id) => async function (dispatch, getState) {
    const state = getState().truckDesigner.truck;

    try {
        dispatch(setPending(true));
        const copiedTruck = await API.call({
            endpoint: API.endpoints['TD/copyTruck'],
            method: 'POST',
            options: {
                data: { uid: id }
            }
        });

        if (copiedTruck.error) throw copiedTruck.error;

        dispatch(setTrucks(state.trucks.push(copiedTruck)));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/truckStore/copyTruck' });
    }
    
    dispatch(setPending(false));
}

export const fetchTrucks = () => async function (dispatch, getState) {
    try {
        dispatch(setPending(true));
        const freshTrucks = await API.call({
            endpoint: API.endpoints['TD/readTrucks'],
            method: 'get'
        });
        if (freshTrucks.error) throw freshTrucks.error;
        dispatch(setTrucks(freshTrucks.map(sub => Map(sub))));
        dispatch(setPending(false));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/truckStore/fetchTrucks' });
    }
}

const INITIAL_STATE = {
    dirty: false,
    pending: false,
    error: null,
    truckId: null,
    truckBackup: null,
    trucks: List()
}

const TruckReducer = function (state = INITIAL_STATE, action) {
    const reduce = {
        
        setTruckId(id) {
            let restoredTrucks = null;
            if (state.dirty && state.truckBackup) {
                const index = state.trucks.findIndex(sub => sub.uid === state.truckId);
                restoredTrucks = state.trucks.splice(index, 1, state.truckBackup);
            }

            return {
                ...state,
                dirty: false,
                truckId: id,
                truckBackup: null,
                trucks: restoredTrucks ?? state.trucks.toList()
            }
        },
        
        updateTruck({ data, merge, dirty }) {
            const index = state.trucks.findIndex(sub => sub.get('uid') === state.truckId);
            const currentTruck = state.trucks.get(index);
            const newTruck = merge ? currentTruck.merge(data) : Map(data);
            const newTrucks = state.trucks.splice(index, 1, newTruck);
            
            const updates = {
                ...state,
                dirty,
                trucks: newTrucks
            };

            const isStateClean = !state.dirty;
            const isUpdateClean = !dirty;
            const isFirstChange = isStateClean && !isUpdateClean;
        
            if (isFirstChange) updates.truckBackup = currentTruck.toMap();
            if (isUpdateClean) updates.truckBackup = null;

            return updates;
        },

        setTrucks(data) {
            return {
                ...state,
                trucks: List(data)
            }
        },

        setError(error) {
            return { ...state, error }
        },

        setPending(status) {
            return { ...state, pending: status }
        }

    }[action.type];

    return reduce == null ? { ...state } : reduce(action.payload);
}

export { TruckReducer };
export default TruckReducer;