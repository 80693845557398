import { List, Map } from "immutable";
import { API } from "../../../Root";
import { catchBlockErrors } from "../../../shared/utils/sentry";

const setPending = (value) => ({
    type: 'setPending',
    payload: value
});

const setError = (error) => ({
    type: 'setError',
    payload: error || null
});

const setCarriages = (carriages, dirty = false) => ({
    type: 'setCarriages',
    payload: { carriages, dirty }
});

export const updateCarriages = (data, merge = true, dirty = true) => ({
    type: 'updateCarriages',
    payload: {
        data,
        merge,
        dirty
    }
});

export const restoreCarriages = () => ({
    type: 'restoreCarriages',
    payload: null
});

export const saveCarriages = () => async function (dispatch, getState) {
    const carriageState = getState().truckDesigner.carriage;
    const truckState = getState().truckDesigner.truck;

    if (!carriageState.dirty) return;

    try {
        dispatch(setPending(true));

        // neccessary to ensure there are never more rows or columns than allowed for the carriage.
        const croppedCarriages = Object.fromEntries(Object.entries(carriageState.carriages.toJS()).map(([carriageName, carriageValue]) => {
            carriageValue.layout = carriageValue.layout
                .filter((_, i) => i < carriageValue.ranks)
                .map(rank => rank.slice(0, carriageValue.columns));
            return [carriageName, carriageValue];
        }));

        const updatedCarriages = await API.call({
            endpoint: API.endpoints['TD/updateTruckCarriage'],
            method: 'POST',
            options: {
                params: { uid: truckState.truckId },
                data: croppedCarriages
            }
        });

        if (updatedCarriages.error) throw updatedCarriages.error;

        dispatch(setCarriages(updatedCarriages, false));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/carriageStore/saveCarriages' });
    }
    
    dispatch(setPending(false));
}

export const fetchCarriages = () => async function (dispatch, getState) {
    const truckState = getState().truckDesigner.truck;
    try {
        dispatch(setPending(true));
        const freshCarriages = await API.call({
            endpoint: API.endpoints['TD/readTruckCarriage'],
            method: 'get',
            options: {
                params: { truckID: truckState.truckId }
            }
        });
        if (freshCarriages.error) throw freshCarriages.error;
        dispatch(setCarriages(freshCarriages, false));
        dispatch(setPending(false));
        dispatch(setError(null));
    } catch (e) {
        console.error(e);
        dispatch(setError(e));
        catchBlockErrors({ error: e, fileFunction: 'truckDesigner/carriageStore/fetchCarriages' });
    }
}

const INITIAL_STATE = {
    dirty: false,
    pending: false,
    error: null,
    carriages: Map({
        clCarriage: null,
        elCarriage: null
    }),
    carriagesBackup: null
};

const CarriageReducer = function (state = INITIAL_STATE, action) {
    const reduce = {

        restoreCarriages() {
            return {
                ...state,
                dirty: false,
                carriages: Map(state.carriagesBackup),
                carriagesBackup: null
            }
        },

        updateCarriages({ data, merge, dirty }) {
            const updatedCarriages = merge ? state.carriages.merge(data) : Map(data); 

            const updates = {
                ...state,
                dirty,
                carriages: updatedCarriages
            };

            const isStateClean = !state.dirty;
            const isUpdateClean = !dirty;
            const isFirstChange = isStateClean && !isUpdateClean;
        
            if (isFirstChange) updates.carriagesBackup = state.carriages.toMap();
            if (isUpdateClean) updates.carriagesBackup = null;

            return updates;
        },

        setCarriages({ carriages, dirty }) {
            return {
                ...state,
                dirty,
                carriages: Map(carriages)
            }
        },

        setError(error) {
            return { ...state, error }
        },

        setPending(status) {
            return { ...state, pending: status }
        }

    }[action.type];

    return reduce == null ? { ...state } : reduce(action.payload);
};

export { CarriageReducer };
export default CarriageReducer;